import Slider from 'react-slick';
import TurnIcon from '@mui/icons-material/SubdirectoryArrowRight';
import './testimonialCarousel.scss';

const TestimonialCarousel = ({ data }) => {
	const settings = {
		infinite: true,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1,
	};

	return (
		<Slider {...settings} className='testimonial-slide' autoplay>
			{data.map((item) => (
				<div className='item' key={item.id}>
					<div className='top'>
						<TurnIcon className='icon' />
						<img src={item.img} alt='' />
						{item.icon}
					</div>
					<div className='center'>
						<p>{item.desc}</p>
					</div>
					<div className='bottom'>
						<h5>{item.name}</h5>
						<h6>{item.title}</h6>
					</div>
				</div>
			))}
		</Slider>
	);
};

export default TestimonialCarousel;
