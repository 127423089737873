import { Card, CardActions, CardContent } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setEmail, setMessage } from '../../../../redux/slices/contactSlice';
import TextInput from '../../../../components/TextInput';
import Button from '../../../../components/Button';
import './contact.scss';

const Contact = () => {
	const { email, message } = useSelector((state) => state.contact);
	const dispatch = useDispatch();

	const handleChange = (input, value) => {
		switch (input) {
			case 'email':
				dispatch(setEmail(value));
				break;

			case 'message':
				dispatch(setMessage(value));
				break;

			default:
				break;
		}
	};

	return (
		<div id='contact'>
			<h2>Contact</h2>
			<div className='contact-container'>
				<div className='left'>
					<img src='/shake.svg' alt='' />
				</div>
				<div className='right'>
					<Card>
						<h5>Feel free to drop me a line!</h5>
						<div className='divider' />
						<CardContent className='content'>
							<TextInput
								label='Email'
								size='small'
								margin='dense'
								value={email}
								onChange={(e) => handleChange('email', e.target.value)}
								fullWidth
							/>
							<TextInput
								label='Message'
								size='small'
								margin='dense'
								multiline
								rows={4}
								value={message}
								onChange={(e) => handleChange('message', e.target.value)}
								fullWidth
							/>
						</CardContent>
						<CardActions className='actions'>
							<Button label='Send' />
						</CardActions>
					</Card>
				</div>
			</div>
		</div>
	);
};

export default Contact;
