import Slider from 'react-slick';
import './portfolioCarousel.scss';

const PortfolioCarousel = ({ data }) => {
	return (
		<Slider className='portfolio-slide'>
			{data.map((item) => (
				<div className='item' key={item.id}>
					<img src={item.img} alt='' />
					<h5>{item.title}</h5>
				</div>
			))}
		</Slider>
	);
};

export default PortfolioCarousel;
