import Slider from 'react-slick';
import './worksCarousel.scss';

const Carousel = ({ data }) => {
	return (
		<Slider className='works-slide'>
			{data.map((item) => (
				<div className='item' key={item.id}>
					<div className='item'>
						<div className='left'>
							<div className='icon-container'>{item.icon}</div>
							<h2>{item.title}</h2>
							<p>{item.desc}</p>
							<span>Projects</span>
						</div>
						<div className='right'>
							<img src={item.img} alt='' />
						</div>
					</div>
				</div>
			))}
		</Slider>
	);
};

export default Carousel;
